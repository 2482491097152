import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfProduct from "@/vue/domain/product/df-product";
import Utils from "@/vue/infrastructure/utils/helpers";
import moment from "moment";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfShoppingProduct from "@/vue/domain/shopping-product/df-shopping-product";
import DfBrand from "@/vue/domain/brand/df-brand";
import DfStore from "@/vue/domain/store/df-store";

@Component({
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
    percentual: (value: string) => {
      const data: string = new Intl.NumberFormat(navigator.language, {
        style: "decimal",
        minimumFractionDigits: 0,
      }).format(parseFloat((parseFloat(value) || 0).toFixed(0)));
      return `-${data}%`;
    },
    price: (value: string, unit: string) => {
      unit = unit ? `${unit}` : "";
      const result: string = new Intl.NumberFormat(navigator.language, {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(value) || 0);
      if (unit == "") {
        return `${result}`;
      } else if (unit == "KG" || unit == "L") {
        return `${unit} ${result}`;
      } else {
        return `${unit}`;
      }
    },
    number: (value: number) => {
      return new Intl.NumberFormat(navigator.language).format(value);
    },
  },
})
export default class DfProductDetailsDataComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  product!: DfProduct;
  @Prop({ type: String, default: "digital_flyer" })
  origin: string;

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get productDateFrom(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_FROM, "DATE");
  }

  get productDateTo(): string {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DATE_TO, "DATE");
  }

  get discount(): string {
    return Utils.getPropertyValue(
      this.product,
      Utils.PROPERTY_PRODUCT_DISCOUNT,
      "STRING"
    );
  }

  get discountPercentual(): number {
    return Utils.getPropertyValue(
      this.product,
      Utils.PROPERTY_PRODUCT_DISCOUNT_PERCENTUAL,
      "NUMBER"
    );
  }

  get startDate(): string {
    const startDate: string = this.productDateFrom ? this.productDateFrom : this.currentPromotion.startDate;
    return startDate ? this.$options.filters!.dateFormat(startDate) : null;
  }

  get endDate(): string {
    const endDate: string = this.productDateTo ? this.productDateTo : this.currentPromotion.endDate;
    return endDate ? this.$options.filters!.dateFormat(endDate) : null;
  }

  get isSelected(): boolean {
    return this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.productDescription) > -1;
  }

  get shoppingList(): Array<DfShoppingProduct> {
    return this.$store.getters.shoppingList;
  }

  set shoppingList(shoppingList: Array<DfShoppingProduct>) {
    this.$store.dispatch("setShoppingList", shoppingList);
  }

  get finalPrice(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE, "NUMBER");
  }

  get finalPriceForUnit(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_FINAL_PRICE_FOR_UNIT, "NUMBER");
  }

  get unitMisure(): string {
    const value = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_UNIT, "STRING");
    return value == "HG" ? this.$t("df-measure-unit.hg.label").toString() : value;
  }

  get ean(): number {
    return Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_EAN, "STRING");
  }

  get points(): number {
    const points: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_POINTS, "NUMBER");
    return points ? this.$options.filters!.number(points) : null;
  }

  get contributionPoints(): number {
    const contributionPoints: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CONTRIBUTION_POINTS, "NUMBER");
    return contributionPoints ? this.$options.filters!.number(contributionPoints) : null;
  }

  get contributionPrice(): number {
    const contributionPrice: number = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_CONTRIBUTION_PRICE, "NUMBER");

    return contributionPrice ? this.$options.filters!.price(contributionPrice) : null;
  }

  get ecommerceEnabled(): boolean {
    const storeProperty: any = Utils.getPropertyValue(this.currentStore, Utils.PROPERTY_ECOMMERCE_ENABLED, "BOOLEAN");
    const brandProperty: any = Utils.getPropertyValue(this.brand, Utils.PROPERTY_ECOMMERCE_ENABLED, "BOOLEAN");

    return storeProperty !== null ? !!storeProperty : !!brandProperty;
  }

  get ecommerceUrl(): string {
    const url: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_URL_ECOMMERCE_SELEX, "STRING");
    return url ? `https://${url}` : null;
  }

  get buttonEcommerceIcon(): string {
    return `${Utils.getPublicPath()}/assets/ecommerce.png`;
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get productDescription(): string {
    const description: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_DESCRIPTION, "STRING");
    const longDescription: string = Utils.getPropertyValue(this.product, Utils.PROPERTY_PRODUCT_LONG_DESCRIPTION, "TEXT");

    return description || longDescription || this.product.description || "";
  }

  private toggleSelection() {
    const index: number = this.shoppingList.findIndex((product: DfShoppingProduct) => product.description === this.productDescription);
    if (index === -1) {
      this.shoppingList = [new DfShoppingProduct(this.productDescription, false), ...this.shoppingList];
      Utils.setAnaliticsTraker({
        event: "add_to_wishlist",
        product: this.productDescription.replace(/[\n\r]/g, " "),
        origin: this.origin,
      });
    } else {
      this.shoppingList = this.shoppingList.slice(0, index).concat(this.shoppingList.slice(index + 1, this.shoppingList.length));
    }
  }
}
